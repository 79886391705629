import AppStateService from '@ajs/services/AppStateService';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LinkService {

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get accountId(): string {
        return this.appStateService.getAccountId();
    }

    // #region Dashboards
    get feedonomicsLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'databases'];
        }
        return ['/'];
    }

    get feedAlertsLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'feed-alerts-dashboard'];
        }
        return ['/', 'feed-alerts-dashboard'];
    }

    get feedStatusLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'status'];
        }
        return ['/', 'status'];
    }

    get feedAMPLink(): string {
        return 'https://feedamp.feedonomics.com/app/#/';
    }

    get feedSupportLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'feedsupport-legacy'];
        }
        return ['/', 'feedsupport-legacy'];
    }

    get feedSupportNewLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'feedsupport-new'];
        }
        return ['/', 'feedsupport-new'];
    }

    get feedSupportFormsLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'feedsupport-forms'];
        }
        return ['/', 'feedsupport-forms'];
    }

    get feedSupportTicketsLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'feedsupport-new', 'tickets'];
        }
        return ['/', 'feedsupport', 'tickets'];
    }

    get feedSupportMissingZohoIdLink(): string[] {
        if (this.accountId) {
            return ['/', this.accountId, 'feedsupport-new', 'missing-zoho-id'];
        }
        return ['/', 'feedsupport-new', 'missing-zoho-id'];
    }

    // #endregion


    // #region Data sources
    get importsLink(): string[] {
        return ['/', this.databaseId, 'imports'];
    }

    importDetailsLink(importId: number): string[] {
        return [
            ...this.importsLink,
            `${importId}`,
            'details'
        ]
    }

    get automateBuildLink(): string[] {
        return ['/', this.databaseId, 'automate-build'];
    }

    get fileMapsLink(): string[] {
        return ['/', this.databaseId, 'file-maps'];
    }

    get extraFieldsLink(): string[] {
        return ['/', this.databaseId, 'extra-fields'];
    }

    get fieldExecutionOrderLink(): string[] {
        return ['/', this.databaseId, 'transformers_field_order'];
    }

    get ftpAccountLink(): string[] {
        return ['/', this.databaseId, 'ftp-account'];
    }

    get triggersLink(): string[] {
        return ['/', this.databaseId, 'ftp-triggers'];
    }
    // #endregion


    // #region Data
    get dataLink(): string[] {
        return ['/', this.databaseId, 'data', '0'];
    }

    get rawDataLink(): string[] {
        return ['/', this.databaseId, 'data', '-1'];
    }

    get dataOverridesLink(): string[] {
        return ['/', this.databaseId, 'data-overrides'];
    }

    get diffToolLink(): string[] {
        return ['/', this.databaseId, 'diff_tool'];
    }

    get genAILink(): string[] {
        return ['/', this.databaseId, 'genai'];
    }

    get genAICategorizationLink(): string[] {
        return ['/', this.databaseId, 'genai', 'categorization'];
    }

    get genAIAttributesLink(): string[] {
        return ['/', this.databaseId, 'genai', 'attributes'];
    }

    get channelListingsLink(): string[] {
        return ['/', this.databaseId, 'channel-listings'];
    }

    get errorOverviewLink(): string[] {
        return ['/', this.databaseId, 'error-overview'];
    }

    get errorResolutionDataLink(): string[] {
        return ['/', this.databaseId, 'error-resolution'];
    }

    get exportRunsLink(): string[] {
        return ['/', this.databaseId, 'export-runs'];
    }
    // #endregion


    // #region Transformers
    get transformersLink(): string[] {
        return ['/', this.databaseId, 'transformers'];
    }

    get bulkTransformersLink(): string[] {
        return ['/', this.databaseId, 'transformers_bulk_import'];
    }

    get mapFieldLink(): string[] {
        return ['/', this.databaseId, 'map-field'];
    }

    get categoryTaxonomyLink(): string[] {
        return ['/', this.databaseId, 'category-taxonomy'];
    }

    get feedAILink(): string[] {
        return ['/', this.databaseId, 'feedai'];
    }

    get classifyTitleLink(): string[] {
        return ['/', this.databaseId, 'feedai', 'classifier'];
    }

    get attributeDataLink(): string[] {
        return ['/', this.databaseId, 'feedai', 'attributor'];
    }
    // #endregion


    // #region Exports
    get exportsLink(): string[] {
        return ['/', this.databaseId, 'exports'];
    }

    get exportsListLink(): string[] {
        return ['/', this.databaseId, 'exports', 'list'];
    }

    get exportTriggersLink(): string[] {
        return ['/', this.databaseId, 'export-triggers'];
    }

    get dataGovernanceLink(): string[] {
        return ['/', this.databaseId, 'data-governance'];
    }

    get summaryGovernanceLink(): string[] {
        return ['/', this.databaseId, 'summary-governance'];
    }

    get exportRandomSamplingLink(): string[] {
        return ['/', this.databaseId, 'export-random-sampling'];
    }
    // #endregion


    // #region Reports
    get reportsAuthorizationLink(): string[] {
        return ['/', this.databaseId, 'reports-authorization', 'google'];
    }

    get skuReportLink(): string[] {
        return ['/', this.databaseId, 'reports-sku'];
    }

    get feedGraderLink(): string[] {
        return ['/', this.databaseId, 'qa', 'feedgrader'];
    }

    get feedQALink(): string[] {
        return ['/', this.databaseId, 'qa', 'feedqa'];
    }

    get storeSummaryReportLink(): string[] {
        return ['/', this.databaseId, 'store-summary-report'];
    }
    // #endregion


    // #region Right nav
    get logsLink(): string[] {
        return ['/', this.databaseId, 'logs'];
    }

    get logsNewLink(): string[] {
        return ['/', this.databaseId, 'logs-new'];
    }

    get oldAlertsLink(): string[] {
        return ['/', this.databaseId, 'alerts-old'];
    }

    get databaseAlerts(): string[] {
        return ['/', this.accountId, 'alerts', 'databases'];
    }

    get databaseManageAlerts(): string[] {
        return ['/', this.accountId, 'alerts', 'databases', 'manage'];
    }

    get databaseManageSubscriberAlerts(): string[] {
        return ['/', this.accountId, 'alerts', 'databases', 'subscribers', 'manage'];
    }

    get commentsLink(): string[] {
        return ['/', this.databaseId, 'comments'];
    }

    get vaultLink(): string[] {
        return ['/', this.databaseId, 'vault'];
    }

    get accountsLink(): string[] {
        return ['/', 'accounts'];
    }

    get profileLink(): string[] {
        return ['/', 'profile'];
    }

    get billingLink(): string[] {
        return ['/', 'billing'];
    }

    get userAccessControlLink(): string[] {
        return ['/', this.accountId, 'users'];
    }

    get releaseNotesLink(): string[] {
        return ['/', 'release-notes'];
    }

    get utilitiesLink(): string[] {
        return ['/', 'utilities'];
    }

    get logoutLink(): string[] {
        return ['/auth_redirect'];
    }

    get cheatSheetLink(): string[] {
        return ['/', 'help', 'cheat-sheet'];
    }

    get keyboardShortcutLink(): string[] {
        return ['/', 'keyboard-shortcuts'];
    }

    get feedAcademyLink(): string {
        if (this.appStateService.user?.isInternal()) {
            return 'https://internal.bigcommerceuniversity.com'
        }

        return 'https://feedacademy.bigcommerceuniversity.com';
    }

    get ahaLink(): string {
        return 'https://feedonomics.ideas.aha.io/';
    }
    // #endregion


    // #region eBay Products
    get ebaySelectionLink(): string[] {
        return ['/', 'ebay-selection'];
    }
    // #endregion

    // #region self-service-help
    get selfServiceHelp(): string[] {
        return ['/', this.databaseId, 'bc-support'];
    }

    get bcSupportExternalLink(): string {
        return 'https://support.bigcommerce.com';
    }

    get bcLiveChatLink(): string {
        return 'https://support.bigcommerce.com/apex/SupportLiveAgentPreChatPage';
    }
    // #endregion

    constructor(
        private readonly appStateService: AppStateService
    ) { }
}
