import { Injectable } from '@angular/core';
import { BigCommerceSelfServiceState } from '@app/core/services/bigcommerce-self-service-state.service';
import { LinkService } from '@app/core/services/link.service';
import { NavGroup, NavItem } from '@app/modules/nav/components/app-vertical-menu/app-vertical-menu.component';
import { faArrowDownToBracket, faCubes, faGrid2, faHome, faQuestionCircle, faShoppingCart } from '@fortawesome/pro-solid-svg-icons';

@Injectable({
    providedIn: 'root'
})
export class NavigationMenuPermissionService {
    constructor(
        private readonly bigCommerceSelfService: BigCommerceSelfServiceState,
        private readonly linkService: LinkService
    ) { }

    public getNavigationGroups(): NavGroup[] {
        const navGroups = [
            {
                id: 'dashboard',
                icon: faHome,
                label: 'Dashboard',
                link: null, // todo: this page does not exist yet
                requiredPermissions: {
                    hasPermission: (): boolean => {
                        return false;  // Non-destructively hides option for all users
                    }
                }
            },
            {
                id: 'data',
                icon: faCubes,
                label: 'Products',
                link: this.linkService.dataLink,
                requiredPermissions: {},
                children: [
                    {
                        id: 'transformers',
                        label: 'Transformers',
                        link: null, // todo: update with new transformers page + angular routing
                        requiredPermissions: {
                            hasPermission: (): boolean => {
                                return this.bigCommerceSelfService.isPaid;
                            }
                        }
                    }
                ]
            },
            {
                id: 'exports',
                icon: faGrid2,
                label: 'Channels',
                link: this.linkService.exportsListLink,
                requiredPermissions: {},
                children: [
                    {
                        id: 'attribute-mapping',
                        label: 'Attribute mapping',
                        link: null,   // todo: this page does not exist yet
                        requiredPermissions: {
                            hasPermission: (): boolean => {
                                return this.bigCommerceSelfService.isPaid;
                            }
                        }
                    },
                    {
                        id: 'taxonomy',
                        label: 'Taxonomy',
                        link: this.linkService.categoryTaxonomyLink,
                        requiredPermissions: {
                            hasPermission: (): boolean => {
                                return this.bigCommerceSelfService.isPaid;
                            }
                        }
                    },
                    {
                        id: 'export-runs',
                        label: 'Sync history',
                        link: this.linkService.exportRunsLink,
                        requiredPermissions: {}
                    },
                    {
                        id: 'reports-auth',
                        label: 'Channel connections',
                        link: this.linkService.reportsAuthorizationLink,
                        requiredPermissions: {
                            hasPermission: (): boolean => {
                                return this.bigCommerceSelfService.isPaid;
                            }
                        }
                    }
                ]
            },
            {
                id: 'feed-amp-orders',
                icon: faShoppingCart,
                label: 'Orders',
                // Todo: update placeholder link in future ticket
                link: ['https://feedamp.feedonomics.com/app/#/733/orders'],
                isExternalLink: true,
                requiredPermissions: {
                    hasPermission: (): boolean => {
                        return this.bigCommerceSelfService.isPaid;
                    }
                }
            },
            {
                id: 'imports',
                icon: faArrowDownToBracket,
                label: 'Data sources',
                link: this.linkService.importsLink,
                requiredPermissions: {}
            },
            {
                id: 'help',
                icon: faQuestionCircle,
                label: 'Help',
                link: this.linkService.selfServiceHelp,
                requiredPermissions: {},
                children: [
                    {
                        id: 'help-center',
                        label: 'Help center',
                        link: [this.linkService.bcSupportExternalLink],
                        isExternalLink: true,
                        requiredPermissions: {}
                    },
                    {
                        id: 'live-chat',
                        label: 'Live chat',
                        link: [this.linkService.bcLiveChatLink],
                        isExternalLink: true,
                        requiredPermissions: {}
                    }
                ]
            }

        ];

        return this.filterByPermissions(navGroups);
    }

    /**
     * Filter out the Nav Groups and Sub Nav items that do not meet permissions
     * @param navGroups
     */
    filterByPermissions(navGroups: NavGroup[]): NavGroup[] {
        return navGroups
            .filter((navGroup: NavGroup) => this.meetsRequiredPermissions(navGroup))
            .map((navGroup: NavGroup) => {
                return {
                    ...navGroup,
                    children: navGroup.children?.filter((subNav: NavItem) => this.meetsRequiredPermissions(subNav))
                };
            });
    }

    /**
     * Checks permissions for the NavGroup and NavItems
     * @param nav
     * @private
     */
    private meetsRequiredPermissions(nav: NavItem): boolean {
        // todo: add check feature flags and other permissions here when needed

        if (typeof (nav.requiredPermissions.hasPermission) === 'function') {
            return nav.requiredPermissions.hasPermission();
        }

        return true;
    }
}
